import axios from '../config/axios';
import fileDownload from 'js-file-download';

export const createAccount = (options) => {
    return axios.post(`/api/users/create-account/`, options);
};

export const resendEmail = (options) => {
    return axios.post(`/api/users/resend-email/`, options);
};

export const getLineInfo = (code, state) => {
    return axios.get(`/api/users/line-auth?code=${code}&state=${state}`);
};

export const createLineAccount = (options) => {
    //not implemented
    return axios.post(`/api/users/create-line-account/`, options);
};

export const fetchVerificationInformation = () => {
    return axios.get('/api/users/verification-information');
};

export const verifyAccountToken = (emailAddress, token) => {
    return axios.post(`/api/users/verify/`, { emailAddress, token});
};

export const verifyEmailUpdateToken = (emailAddress, token) => {
    return axios.post(`/api/users/verify-update-email/`, { emailAddress, token});
};

export const login = (username, password) => {
    return axios.post(`/api/users/login`, { username, password });
};

export const requestPasswordReset = (emailAddress) => {
    return axios.post(`/api/users/request-password-reset`, { emailAddress });
};

export const resetPassword = (emailAddress, token, password, confirmPassword) => {
    return axios.post(`/api/users/reset-password`, { emailAddress, token, password, confirmPassword });
};

export const setPassword = (options) => {
    return axios.post(`/api/users/set-password`, options);
};

export const fetchUserUpload = fileName => {
    return axios.get(`/api/users/upload/${fileName}`)
        // .then(response => Buffer.from(response.data, 'binary').toString('base64'));
        .then(response => `data:${response.data.mimetype};base64,${Buffer.from(response.data.buffer.data).toString('base64')}`)
        .catch(e => console.log(e));
};

export const checkValidToken = token => {
    return axios.post(`/api/users/check-token`, { token });
}

export const updateEmail = updatedEmail => {
    return axios.post('/api/users/update-email', updatedEmail);
}

export const updatePassword = updatedPassword => {
    return axios.post('/api/users/update-password', updatedPassword);
}

export const updateEmailPreference = ccResult => {
    return axios.post('/api/organizations/update-email-preference', { ccResult });
}

export const downloadResultPdf = registrationId => {
    return axios.get(`/api/registrations/users/download-pdf-result/${registrationId}`, { responseType: 'blob' }).then(response => {
        fileDownload(response.data, 'result.pdf');
    });
}