import axios from 'axios';
import { logoutUser } from '../states/authentication.slice';
import { store } from '../states/create-store';

const api = axios.create({
     // withCredentials: true,
     crossDomain: true,
});

api.interceptors.request.use(function (config) {
     const authentication = store.getState().authentication;
     if(authentication.user && authentication.user.accessToken) {
          config.headers.Authorization = `Bearer ${authentication.user.accessToken}`;
     }
     return config;
 });

api.interceptors.response.use(function (response) {
     return response;
 }, function (error) {
     if (error.response && 401 === error.response.status) {
          store.dispatch(logoutUser());
     } else {
         return Promise.reject(error);
     }
 });

export default api;