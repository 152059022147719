import { createSlice } from '@reduxjs/toolkit';

const inititalState = {
    isOpen: false,
    isOpenDashSidebar: false
}

export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState: inititalState,
    reducers: {
        setIsOpen: (state, action) => {
            state.isOpen = action.payload;
        },
        setIsOpenDashSidebar: (state, action) => {
            state.isOpenDashSidebar = action.payload;
        },

    }
});

export const { setIsOpen, setIsOpenDashSidebar } = sidebarSlice.actions;

export default sidebarSlice.reducer;
