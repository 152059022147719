import { createSlice } from '@reduxjs/toolkit';
import { fetchRegistrations } from '../services/registrations.service';

const inititalState = {
    registrations: [],
    currentRegistration: null,
}

export const registrationsSlice = createSlice({
    name: 'registrations',
    initialState: inititalState,
    reducers: {
        setRegistrations: (state, action) => {
            state.registrations = action.payload;
        },
        setRegistration: (state, action) => {
            state.currentRegistration = action.payload;
        },
    }
});

export const { setRegistrations, setRegistration } = registrationsSlice.actions;

export const fetchRegistrationsAsync = () => dispatch => {
    fetchRegistrations()
        .then(response => dispatch(setRegistrations(response.data)))
        .catch(error => console.error(error));
};

export default registrationsSlice.reducer;