import axios from '../config/axios';

export const fetchRegistrations = () => {
    return axios.get('/api/registrations');
};

export const saveBarcode = (barcode, registrationId) => {
    return axios.post('/api/registrations/scan-barcode', { barcode, registrationId });
};

export const saveConsent = (signature, registrationId) => {
    return axios.post('/api/registrations/consent', { signature, registrationId });
};

export const savePurpose = (registrationId) => {
    return axios.post('/api/registrations/read-purpose', { registrationId });
};

export const saveQuestionnaire = (questionnaire) => {
    return axios.post('/api/registrations/questionnaire', questionnaire);
};

export const completeRegistration = (registrationId) => {
    return axios.post('/api/registrations/complete', { registrationId });
}

export const removeRegistration = (registrationId) => {
    return axios.delete(`/api/registrations/remove/${registrationId}`);
}