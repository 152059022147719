import { createSlice } from '@reduxjs/toolkit';
import { fetchPatients } from '../services/patients.service';

const inititalState = {
    patients: [],
    selectedPatient: {},
    errors: null,
    defaultUser: null
}

export const serializePatientPayload = action => {
    if(
        action.payload.dateOfBirth?.date !== null && 
        typeof action.payload.dateOfBirth?.date === 'object' &&
        typeof action.payload.dateOfBirth?.date.getMonth === 'function') {
        action.payload.dateOfBirth.date = action.payload.dateOfBirth?.date.valueOf();
    }
    
    if(
        action.payload.identificationExpirationDate?.date !== null && 
        typeof action.payload.identificationExpirationDate?.date === 'object' &&
        typeof action.payload.identificationExpirationDate?.date.getMonth === 'function') {
        action.payload.identificationExpirationDate.date = action.payload.identificationExpirationDate?.date?.valueOf();
    }
    
    return action;
};

export const patientsSlice = createSlice({
    name: 'patients',
    initialState: inititalState,
    reducers: {
        setPatients: (state, action) => {
            state.patients = action.payload;
        },
        setSelectedPatient: (state, action) => {
            // console.log('here!');
            // if(typeof action.payload.dateOfBirth?.date === 'object') {
            //     action.payload.dateOfBirth.date = action.payload.dateOfBirth?.date.valueOf();
            // }
            state.selectedPatient = action.payload;
        },
        setDefaultUser: (state, action) => {
            state.defaultUser = action.payload;
        },
        setErrors: (state, action) => {
            state.errors = action.payload;
        }
    }
});

export const { setPatients, setSelectedPatient, setDefaultUser, setErrors } = patientsSlice.actions;

export const fetchPatientsAsync = () => dispatch => {
    fetchPatients()
        .then(response => {
            dispatch(setPatients(response.data));
            const defaultPatient = response.data.find(p => p.isDefault);
            if (defaultPatient) {
                dispatch(setDefaultUser(defaultPatient));
            }

        })
        .catch(error => console.error(error));
};

export const savePatientAsync = () => dispatch => {
    
};

export default patientsSlice.reducer;