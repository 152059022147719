// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-activate-js": () => import("./../../../src/pages/account/activate.js" /* webpackChunkName: "component---src-pages-account-activate-js" */),
  "component---src-pages-account-create-js": () => import("./../../../src/pages/account/create.js" /* webpackChunkName: "component---src-pages-account-create-js" */),
  "component---src-pages-account-create-line-js": () => import("./../../../src/pages/account/create-line.js" /* webpackChunkName: "component---src-pages-account-create-line-js" */),
  "component---src-pages-account-login-js": () => import("./../../../src/pages/account/login.js" /* webpackChunkName: "component---src-pages-account-login-js" */),
  "component---src-pages-account-register-js": () => import("./../../../src/pages/account/register.js" /* webpackChunkName: "component---src-pages-account-register-js" */),
  "component---src-pages-account-reset-password-js": () => import("./../../../src/pages/account/reset-password.js" /* webpackChunkName: "component---src-pages-account-reset-password-js" */),
  "component---src-pages-account-set-password-js": () => import("./../../../src/pages/account/set-password.js" /* webpackChunkName: "component---src-pages-account-set-password-js" */),
  "component---src-pages-account-verification-js": () => import("./../../../src/pages/account/verification.js" /* webpackChunkName: "component---src-pages-account-verification-js" */),
  "component---src-pages-account-verify-email-update-js": () => import("./../../../src/pages/account/verify-email-update.js" /* webpackChunkName: "component---src-pages-account-verify-email-update-js" */),
  "component---src-pages-account-verify-js": () => import("./../../../src/pages/account/verify.js" /* webpackChunkName: "component---src-pages-account-verify-js" */),
  "component---src-pages-asct-js": () => import("./../../../src/pages/asct.js" /* webpackChunkName: "component---src-pages-asct-js" */),
  "component---src-pages-corporations-asct-js": () => import("./../../../src/pages/corporations/asct.js" /* webpackChunkName: "component---src-pages-corporations-asct-js" */),
  "component---src-pages-corporations-faqs-js": () => import("./../../../src/pages/corporations/faqs.js" /* webpackChunkName: "component---src-pages-corporations-faqs-js" */),
  "component---src-pages-corporations-features-js": () => import("./../../../src/pages/corporations/features.js" /* webpackChunkName: "component---src-pages-corporations-features-js" */),
  "component---src-pages-corporations-index-js": () => import("./../../../src/pages/corporations/index.js" /* webpackChunkName: "component---src-pages-corporations-index-js" */),
  "component---src-pages-corporations-privacy-policy-js": () => import("./../../../src/pages/corporations/privacy-policy.js" /* webpackChunkName: "component---src-pages-corporations-privacy-policy-js" */),
  "component---src-pages-corporations-terms-and-conditions-js": () => import("./../../../src/pages/corporations/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-corporations-terms-and-conditions-js" */),
  "component---src-pages-dashboard-account-info-js": () => import("./../../../src/pages/dashboard/account-info.js" /* webpackChunkName: "component---src-pages-dashboard-account-info-js" */),
  "component---src-pages-dashboard-edit-info-js": () => import("./../../../src/pages/dashboard/edit-info.js" /* webpackChunkName: "component---src-pages-dashboard-edit-info-js" */),
  "component---src-pages-dashboard-index-js": () => import("./../../../src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-dashboard-registered-info-js": () => import("./../../../src/pages/dashboard/registered-info.js" /* webpackChunkName: "component---src-pages-dashboard-registered-info-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logout-js": () => import("./../../../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-organization-edit-info-js": () => import("./../../../src/pages/organization/edit-info.js" /* webpackChunkName: "component---src-pages-organization-edit-info-js" */),
  "component---src-pages-organization-index-js": () => import("./../../../src/pages/organization/index.js" /* webpackChunkName: "component---src-pages-organization-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-register-check-js": () => import("./../../../src/pages/register/check.js" /* webpackChunkName: "component---src-pages-register-check-js" */),
  "component---src-pages-register-consent-js": () => import("./../../../src/pages/register/consent.js" /* webpackChunkName: "component---src-pages-register-consent-js" */),
  "component---src-pages-register-index-js": () => import("./../../../src/pages/register/index.js" /* webpackChunkName: "component---src-pages-register-index-js" */),
  "component---src-pages-register-info-js": () => import("./../../../src/pages/register/info.js" /* webpackChunkName: "component---src-pages-register-info-js" */),
  "component---src-pages-register-scan-js": () => import("./../../../src/pages/register/scan.js" /* webpackChunkName: "component---src-pages-register-scan-js" */),
  "component---src-pages-register-symptoms-and-history-js": () => import("./../../../src/pages/register/symptoms-and-history.js" /* webpackChunkName: "component---src-pages-register-symptoms-and-history-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-test-done-js": () => import("./../../../src/pages/test/done.js" /* webpackChunkName: "component---src-pages-test-done-js" */),
  "component---src-pages-test-index-js": () => import("./../../../src/pages/test/index.js" /* webpackChunkName: "component---src-pages-test-index-js" */),
  "component---src-pages-test-step-2-js": () => import("./../../../src/pages/test/step2.js" /* webpackChunkName: "component---src-pages-test-step-2-js" */),
  "component---src-pages-test-step-3-js": () => import("./../../../src/pages/test/step3.js" /* webpackChunkName: "component---src-pages-test-step-3-js" */)
}

