import { configureStore } from '@reduxjs/toolkit';
import authenticationReducer from './authentication.slice';
import patientsReducer from './patients.slice';
import registrationsReducer from './registrations.slice';
import sidebarReducer from './sidebar.slice';
import navbarReducer from './navbar.slice';

const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    patients: patientsReducer,
    registrations: registrationsReducer,
    sidebar: sidebarReducer,
    navbar: navbarReducer,
  }
});

export { store };