import { createSlice } from '@reduxjs/toolkit';
import { login } from '../services/users.service';

const inititalState = () => {
    try {
        const initialUserState = window.localStorage.getItem('user');
        if(initialUserState) {
            return JSON.parse(initialUserState);
        }
    }
    catch(e) {
        // Don't do anything
    }

    return {
        loggedIn: false,
        authenticationError: '',
        user: null,
    };
}

export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState: inititalState(),
    reducers: {
        setUser: (state, action) => {
            state.loggedIn = true;
            state.user = action.payload;
            state.authenticationError = '';
            window.localStorage.setItem('user', JSON.stringify(state));
        },
        setAuthenticationError: (state, action) => {
            state.loggedIn = false;
            state.authenticationError = action.payload;
        },
        logoutUser: (state, action) => {
            state.loggedIn = false;
            window.localStorage.removeItem('user');
        },
        setIsValidateToken: (state, action) => {
            if(!action.payload) {
                state.loggedIn = false;
            }
        }
    },
    extraReducers: builder => {
        builder.addDefaultCase(( state, action) => {
        });
    }
});

export const { setUser, setAuthenticationError, logoutUser, setIsValidateToken, updateUserInfo } = authenticationSlice.actions;

export const loginAsync = (username, password, route = '') => dispatch => {
    login(username, password)
        .then(response => { 
            dispatch(setUser(response.data)); 
            if(route) {
                // navigate(route);
            }
        })
        .catch(error => dispatch(setAuthenticationError(error.response.data.message)));
};

export const updateuserInfo = (user) => dispatch => {

};

export const selectUser = state => state.authentication.user;

export const selectLoggedIn = state => state.authentication.loggedIn;

export default authenticationSlice.reducer;