import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isCorporation: false,
}

export const navbarSlice = createSlice({
    name: 'navbar',
    initialState,
    reducers: {
        setIsCorporation: (state, action) => {
            state.isCorporation = action.payload;
        },

    }
});

export const { setIsCorporation } = navbarSlice.actions;

export default navbarSlice.reducer;
