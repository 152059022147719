import axios from '../config/axios';

export const fetchPatients = () => {
    return axios.get('/api/patients');
};

export const savePatient = (information) => {
    return axios.post('/api/patients/save', information);
};

export const updatePatientInfo = (information) => {
    return axios.patch('/api/patients/update-info', information);
};

export const uploadIdentification = ref => {
    const { files } = ref.current;
    const formData = new FormData();
    formData.append('file', files[0]);

    return axios.post('/api/patients/upload-identification', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    });
};